import { useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Legal = ({page, title, BG}) => {

    useEffect(() => {
     page(2) 
     title('Legal Notice of Matthieu Sibert International')
    }, [])
    
    return ( 
        <HelmetProvider>
        <main className="Legal">
             <Helmet htmlAttributes={{lang:"en"}}>
                <title>Matthieu Sibert International - Legal</title>
                <meta name="description" content="Terms of Service and Privacy Policy of Matthieu Sibert International" />
                <link rel="canonical" href="https://www.matthieu-sibert-international.me/Legal"/>
            </Helmet>
            <section>
                <h2>Terms of Service</h2>
                <article>
                    <h3>1. Introduction</h3>
                    <p>Welcome to Matthieu Sibert International ("Company", "we", "us", or "our"). These Terms of Service ("Terms") govern your use of our website, mobile applications, software, and any related services (collectively, the "Service"). By using our Service, you agree to these Terms. If you do not agree with any part of these Terms, do not use our Service.</p>
                </article>
                <article>
                    <h3>2. User Accounts</h3>
                    <p>To access certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to keep your account information updated. You are responsible for maintaining the confidentiality of your account information and are responsible for all activities that occur under your account. You must notify us immediately of any unauthorized use of your account.</p>
                </article>
                <article>
                    <h3>3. Acceptable Use</h3>
                    <p>You agree to use the Service in compliance with all applicable laws and regulations. You must not:</p>
                    <ul>
                        <li>Use the Service for any illegal or unauthorized purpose.</li>
                        <li>Upload or transmit any content that is unlawful, defamatory, obscene, or otherwise objectionable.</li> 
                        <li>Engage in any activity that could harm the Service or interfere with other users' use of the Service.</li>
                        <li>Attempt to gain unauthorized access to our systems or networks.</li>
                    </ul>
                </article>
                <article>
                    <h3>4. Intellectual Property</h3>
                    <p>All content, trademarks, logos, and other intellectual property displayed on the Service are the property of Matthieu Sibert International or its licensors. You agree not to use, reproduce, distribute, or create derivative works based on our intellectual property without our prior written consent.</p>
                </article>
                <article>
                    <h3>5. Privacy</h3>
                    <p>Our Privacy Policy describes how we collect, use, and disclose information from users of the Service. By using the Service, you agree to the terms of our Privacy Policy.</p>
                </article>
                <article>
                    <h3>6. Third-Party Links</h3>
                    <p>The Service may contain links to third-party websites or services. We do not endorse or assume any responsibility for these third-party sites. If you access any third-party site, you do so at your own risk, and these Terms no longer apply.</p>
                </article>
                <article>
                    <h3>7. Disclaimer of Warranties</h3>
                    <p>The Service is provided "as is" and "as available." We disclaim all warranties, whether express or implied, including any implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not guarantee that the Service will be error-free or uninterrupted.</p>
                </article>
                <article>
                    <h3>8. Limitation of Liability</h3>
                    <p>To the maximum extent permitted by law, Matthieu Sibert International shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of the Service, even if we have been advised of the possibility of such damages.</p>
                </article>
                <article>
                    <h3>9. Indemnification</h3>
                    <p>You agree to indemnify and hold harmless Matthieu Sibert International, its affiliates, officers, directors, and employees from any claims, damages, liabilities, or expenses arising from your use of the Service or violation of these Terms.</p>
                </article>
                <article>
                    <h3>10. Termination</h3>
                    <p>We reserve the right to terminate or suspend your access to the Service at any time, without prior notice or liability, for any reason, including violation of these Terms.</p>
                </article>
                <article>
                    <h3>11. Governing Law</h3>
                    <p>These Terms shall be governed by and construed in accordance with the laws of the European Union. Any legal action or proceeding arising out of or related to these Terms or the Service must be brought exclusively in the courts located in the European Union.</p>
                </article>
                <article>
                    <h3>12. Changes to Terms</h3>
                    <p>We reserve the right to update or modify these Terms at any time. If we make material changes, we will notify you by email or through the Service. By continuing to use the Service after changes are made, you agree to the revised Terms.</p>
                </article>
                <article>
                    <h3>13. Contact Information</h3>
                    <p>If you have any questions or concerns about these Terms, please contact us at <a href="mailto:legal@matthieu-sibert-international.me?subject=Matthieu Sibert International Terms of Service">legal@matthieu-sibert-international.me</a></p>
                </article>
           </section>
           <hr/>
           <section>
                <h2>Privacy Policy</h2>
                <article>
                    <h3>1. Introduction</h3>
                    <p>At Matthieu Sibert International ("Company", "we", "us", or "our"), we respect your privacy and are committed to protecting your personal information. This Privacy Policy describes how we collect, use, disclose, and safeguard your information when you use our website, mobile applications, software, or any related services (collectively, the "Service"). By accessing or using our Service, you agree to the terms of this Privacy Policy.</p>
                </article>
                <article>
                    <h3>2. Information We Collect</h3>
                    <h4>2.1 Personal Information</h4>
                    <p>We may collect personal information that you voluntarily provide, such as your name, email address, phone number, postal address, or other contact information. This information is collected when you register an account, contact us, or use certain features of our Service.</p>
                    <h4>2.2 Usage Data</h4>
                    <p>We may collect information about how you use our Service, such as the pages you visit, the time spent on those pages, and other analytical data. This information helps us understand how users interact with our Service and improve it over time.</p>
                    <h4>2.3 Device Information</h4>
                    <p>We may collect information about the device you use to access our Service, including your device type, operating system, browser type, and unique device identifiers.</p>
                </article>
                <article>
                    <h3>3. How We Use Your Information</h3>
                    <p>We may use your information for various purposes, including:</p>
                    <ul>
                        <li>To operate, maintain, and improve our Service.</li>
                        <li>To process transactions and send you related information, such as confirmations and invoices.</li>
                        <li>To respond to your inquiries and provide customer support.</li>
                        <li>To send you marketing communications, with your consent.</li>
                        <li>To detect and prevent fraud or unauthorized access.</li>
                    </ul>
                </article>
                <article>
                    <h3>4. Disclosure of Your Information</h3>
                    <p>We may share your information in the following circumstances:</p>
                    <ul>
                        <li>With third-party service providers who perform services on our behalf, such as payment processing, hosting, or analytics.</li>
                        <li>To comply with legal obligations or respond to lawful requests from government authorities.</li>
                        <li>To protect our rights, property, or safety, or the rights, property, or safety of others.</li>
                        <li>In connection with a business transaction, such as a merger, acquisition, or sale of assets.</li>
                    </ul>
                </article>
                <article>
                    <h3>5. Cookies and Similar Technologies</h3>
                    <p>We use cookies and similar technologies to collect information about your use of our Service. Cookies are small text files that are stored on your device. You can set your browser to refuse cookies, but this may affect your ability to use certain features of our Service.</p>
                </article>
                <article>
                    <h3>6. Your Rights and Choices</h3>
                    <p>Depending on your jurisdiction, you may have certain rights regarding your personal information, including:</p>
                    <ul>
                        <li>The right to access, correct, or delete your personal information.</li>
                        <li>The right to object to or restrict certain types of processing.</li>
                        <li>The right to withdraw consent, where applicable.</li>
                    </ul>
                    <p>To exercise any of these rights, please contact us at <a href="mailto:legal@matthieu-sibert-international.me?subject=Matthieu Sibert International Terms of Service">legal@matthieu-sibert-international.me</a></p>
                </article>
                <article>
                    <h3>7. Data Security</h3>
                    <p>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no security system is completely foolproof, and we cannot guarantee the absolute security of your information.</p>
                </article>
                <article>
                    <h3>8. Data Retention</h3>
                    <p>We retain your personal information for as long as necessary to fulfill the purposes described in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
                </article>
                <article>
                    <h3>9. Children's Privacy</h3>
                    <p>Our Service is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected such information, we will take steps to delete it.</p>
                </article>
                <article>
                    <h3>10. Changes to This Privacy Policy</h3>
                    <p>We reserve the right to update or modify this Privacy Policy at any time. If we make material changes, we will notify you by email or through the Service. By continuing to use the Service after changes are made, you agree to the revised policy.</p>
                </article>
                <article>
                    <h3>11. Contact Information</h3>
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:legal@matthieu-sibert-international.me?subject=Matthieu Sibert International Terms of Service">legal@matthieu-sibert-international.me</a></p>
                </article>
           </section>
        </main>
        </HelmetProvider>
     );
}
 
export default Legal;