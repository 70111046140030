import axios from 'axios';
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const ContactFr = ({ page, title }) => {
    const [LastName, setLastName] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [mail, setMail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const makeLastName = (e) => {
        setLastName(e.target.value);
    };

    const makeFirstName = (e) => {
        setFirstName(e.target.value);
    };

    const makeMail = (e) => {
        setMail(e.target.value);
    };

    const makeMsg = (e) => {
        setMessage(e.target.value);
    };

    const makeReset = () => {
        setLastName('');
        setFirstName('');
        setMail('');
        setMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (LastName.length === 0) {
            alert('Veuillez entrer votre nom');
        } else if (FirstName.length === 0) {
            alert('Veuillez entrer votre prénom');
        } else if (mail.length === 0) {
            alert('Veuillez entrer adresse email');
        } else if (message.length === 0) {
            alert('Veuillez entrer votre message');
        } else {
            setIsSubmitting(true);
            const formData = new FormData();
            formData.append('LastName', LastName);
            formData.append('FirstName', FirstName);
            formData.append('mail', mail);
            formData.append('message', message);
            try {
                const response = await axios.post('https://matthieu-sibert-international.me/ContactFr/index.php', formData);
                if (response.status === 200) {
                    alert('Message envoyé avec succès!');
                    makeReset();
                } else {
                    alert("Impossible d'envoyer le message.");
                }
            } catch (error) {
                console.error("Une erreur est survenue lors de l'envoi du message:", error);
                alert("Une erreur s'est produite lors de l'envoi de votre message. Veuillez réessayer plus tard.");
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    useEffect(() => {
        page(7);
        title('Informations de Contact de Matthieu Sibert International');
    }, [page, title]);

    return (
        <HelmetProvider>
        <main className="contact">
            <Helmet htmlAttributes={{ lang: "fr" }}>
                <title>Matthieu Sibert International - Contact</title>
                <meta name="description" content="Formulaire de contact de Mattieu Sibert International" />
                <link rel="canonical" href="https://www.matthieu-sibert-international.me/FR/Contact" />
            </Helmet>
            <section>
                <h2>Formulaire de Contact</h2>
                <form onSubmit={handleSubmit} autoComplete="true">
                    <div>
                        <label htmlFor="LastNameInput">Nom :</label>
                        <input name="LastName" id="LastNameInput" type="text" placeholder="Veuillez entrer votre nom " value={LastName} onChange={makeLastName} />
                    </div>
                    <div>
                        <label htmlFor="FirstNameInput">Prénom :</label>
                        <input id="FirstNameInput" name="FirstName" type="text" placeholder="Veuillez entrer votre prénom" value={FirstName} onChange={makeFirstName} />
                    </div>
                    <div>
                        <label htmlFor="mailInput">Email :</label>
                        <input id="mailInput" name="mail" type="email" placeholder="Veuillez entrer votre adresse email" value={mail} onChange={makeMail} />
                    </div>
                    <div>
                        <label htmlFor="messageInput">Votre message :</label>
                        <textarea id="messageInput" name="message" rows={25} value={message} onChange={makeMsg}></textarea>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <button type="reset" style={{ backgroundColor: '#fa4646' }} onClick={makeReset}>Reset</button>
                        <button type="submit" style={{ backgroundColor: '#30e395' }} disabled={isSubmitting}>
                            {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
                        </button>
                    </div>
                </form>
            </section>
        </main>
        </HelmetProvider>
    );
};

export default ContactFr;