import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Home = ({page, title}) => {

    useEffect(() => {
      page(0)
      title('A Multifaceted Journey through Matthieu Sibert International')
    }, [])
    

    return ( 
        <HelmetProvider>
        <main className="home">
            <Helmet htmlAttributes={{lang:"en"}}>
                <title>Matthieu Sibert International - Homepage</title>
                <meta name="description" content="Welcome to the world of Matthieu Sibert International, where innovation knows no bounds. Today, we embark on a journey that explores the dynamic realms of mobile apps, websites, data science, finance and video games." />
                <link rel="canonical" href="https://www.matthieu-sibert-international.me/"/>
            </Helmet>
            <section>
                <h2>Discover a wide range of amazing products</h2>
                <article>
                    <h3>Mobile apps</h3>
                    <hr/>
                    <img src={process.env.PUBLIC_URL + '/images/1x/mobileApp.png'} loading="lazy" title="Mobile app logo" alt="A dark mobile phone with Matthieu Sibert International logo"/>
                    <div>
                        <p>In the era of on-the-go connectivity, our mobile apps stand as beacons of convenience and efficiency. From intuitive user interfaces to seamless functionalities, each app we craft is a testament to our commitment to enhancing user experiences. Whether it's streamlining daily tasks or fostering engagement, our mobile apps empower users to navigate their digital lives with ease.</p>
                    </div>
                </article>
                <article>
                    <h3>Websites</h3>
                    <hr/>
                    <div>
                        <p>In the virtual realm, websites serve as gateways to your brand's identity. At Matthieu Sibert International, we specialize in weaving digital narratives that captivate and inspire. From sleek designs to robust backend infrastructures, our websites are tailored to leave a lasting impression. With a keen eye on user experience and cutting-edge technologies, we transform concepts into captivating online experiences.</p>
                    </div>
                    <img src={process.env.PUBLIC_URL + '/images/1x/website.png'} loading="lazy" title="Website logo" alt="A dark computer with Matthieu Sibert International logo"/>
                </article>
                <article>
                    <h3>Data science</h3>
                    <hr/>
                    <img src={process.env.PUBLIC_URL + '/images/1x/dataScience.png'} loading="lazy" title="Data science logo" alt="A white robot"/>
                    <div>
                        <p>In the age of big data, insights reign supreme. Leveraging the power of data science, we unlock hidden potentials and unravel actionable insights. Through advanced analytics and predictive modeling, we empower businesses to make informed decisions, optimize processes, and stay ahead of the curve. From personalized recommendations to predictive maintenance, our data-driven solutions drive efficiency and foster growth.</p>
                    </div>
                </article>
                <article>
                    <h3>Video games</h3>
                    <hr/>
                    <img src={process.env.PUBLIC_URL + '/images/1x/videoGames.png'} loading="lazy" title="Video games logo" alt="A white fantasy console controller"/>
                    <div>
                        <p>Entertainment knows no bounds in the realm of video games. At Matthieu Sibert International, we harness the power of play to create immersive experiences that transcend boundaries. With a blend of creativity and technical prowess, we redefine interactive entertainment and push the boundaries of imagination.</p>
                    </div>
                </article>
                <article>
                    <h3>Finance</h3>
                    <hr/>
                    <div>
                        <p>Discover a range of innovative financial products designed to meet your diverse needs. From tailored investment options to comprehensive risk management solutions, our offerings aim to help you achieve your financial goals with confidence. Partner with us for expert guidance and flexible financial tools.</p>
                    </div>
                    <img src={process.env.PUBLIC_URL + '/images/1x/finance.png'} loading="lazy"title="Finance logo" alt="A 3D financial graphic"/>
                </article>
            </section>
            <section className="tictactoe">
                <h2>TicTacToe.Ai is here</h2>
                <img src={process.env.PUBLIC_URL + '/images/logo.png'} loading="lazy" title="Mobile app logo" alt="A dark mobile phone with Matthieu Sibert International logo"/>
                <p>Looking for a quick challenge or a fun break? Test out our Tic Tac Toe app powered by AI! Whether you’re a beginner or a pro, our AI is designed to anticipate moves and play strategically. Challenge the AI and put your skills to the test! Play against a virtual opponent that’s always ready for a game.</p>
                <p><a href="https://matthieu-sibert-international.me/TicTacToe.AI/" target="_blank">Let's have a game !</a></p>
            </section>
            <p style={{textAlign:'center'}}>For inquiries, collaborations, or to explore our portfolio further, feel free to <Link to='Contact'>reach out to us</Link>.</p>
        </main>
        </HelmetProvider>
     );
}
 
export default Home;