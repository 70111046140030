import { useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const AboutFR = ({page, title}) => {

    useEffect(() => {
        page(5) 
        title('Détails sur les produits de Matthieu Sibert International')
       }, [])

    return ( 
        <HelmetProvider>
        <main className="About">
            <Helmet htmlAttributes={{lang:"fr"}}>
                <title>Matthieu Sibert International - Produits</title>
                <meta name="description" content="Les produits informatiques et technologiques de Matthieu Sibert International" />
                <link rel="canonical" href="https://www.matthieu-sibert-international.me/Fr/A-propos"/>
            </Helmet>
            <section className="mobileApps">
                <h2>Applications Mobiles</h2>
                <p>À l'ère de la connectivité nomade, nos applications mobiles sont synonymes de commodité et d'efficacité. Des interfaces utilisateur intuitives aux fonctionnalités transparentes, chaque application que nous créons témoigne de notre engagement à améliorer l'expérience utilisateur. Qu'il s'agisse de rationaliser les tâches quotidiennes ou de favoriser l'engagement, nos applications mobiles permettent aux utilisateurs de naviguer facilement dans leur vie numérique.</p>
                <img src={process.env.PUBLIC_URL + '/images/mobiles.png'} loading='lazy' title="Exemple d’applications mobiles de Matthieu sibert International" alt="Deux téléphones noirs utilisant matthieu-sibert -international.me"/>
                <p>Nos applications sont des solutions multiplateformes développées à l'aide de React Native et de Node.js, conçue pour offrir des expériences transparentes sur les appareils iOS et Android. Tirant parti de la puissance de React Native pour le front-end et Node.js pour le back-end, nos applications offrent une architecture robuste et évolutive.</p>
                    <p>Avec React Native, nous créons une interface utilisateur cohérente et réactive qui semble naturelle sur les deux plateformes. Cela nous permet de développer une base de code unique tout en conservant l'apparence native que les utilisateurs attendent de leurs appareils.</p>
                    <p>En back-end, Node.js fournit un environnement rapide et évolutif pour le traitement des demandes, la gestion des bases de données et la connexion avec des API tierces. Cette combinaison permet à nos applications d'être à la fois légère et performante, assurant un fonctionnement fluide même sous une charge d'utilisateur élevée.</p>
                    <p>Nos applications comprennent une variété de fonctionnalités conçues pour améliorer l'expérience utilisateur, telles que:</p>
                    <ul>
                        <li>Synchronisation des données en temps réel</li>
                        <li>Navigation et interface utilisateur intuitives</li>
                        <li>Intégration avec les médias sociaux et d'autres services externes</li>
                        <li>Notifications push et autres outils d'engagement des utilisateurs</li>
                    </ul>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/images/IOS.png'} loading='lazy' title="Logo de IOS" alt="Logo de IOS"/>
                        <img src={process.env.PUBLIC_URL + '/images/Android.png'} loading='lazy'title="Logo de Android" alt="Logo de Android"/>
                        <img src={process.env.PUBLIC_URL + '/images/react-native-1.svg'} loading='lazy' title="Logo de React native" alt="Logo de React native"/>
                        <img src={process.env.PUBLIC_URL + '/images/node.png'} loading='lazy'title="Logo de Node.js" alt="Logo de Node.js"/>
                    </div>
            </section>
            <section className="websites">
                <h2>Sites Web</h2>
                <p>Dans le domaine virtuel, les sites Web servent de passerelles à l'identité de votre marque. Chez Matthieu Sibert International, nous nous spécialisons dans le tissage de récits numériques qui captivent et inspirent. Des conceptions épurées aux infrastructures d'arrière-plan robustes, nos sites Web sont conçus pour laisser une impression durable. Avec un œil attentif sur l'expérience utilisateur et les technologies de pointe, nous transformons les concepts en expériences en ligne captivantes.</p>
                <img src={process.env.PUBLIC_URL + '/images/websites.png'} loading='lazy' id="websiteImage" title="Exemple de site web de Matthieu Sibert International" alt="Un ordinateur sombre utilisant le site matthieu-sibert-international"/>
                <p>React est une bibliothèque JavaScript frontale qui nous permet de construire des interfaces utilisateur hautement interactives. Avec React, nous veillons à ce que notre site Web soit rapide, réactif et facile à naviguer. L'architecture basée sur les composants de React nous permet de développer des composants d'interface utilisateur réutilisables, rendant notre code plus maintenable et évolutif. Cela signifie que vous obtenez une expérience fluide et engageante chaque fois que vous visitez notre site.</p>
                <img src={process.env.PUBLIC_URL + '/images/React.png'} loading='lazy'title="Logo de React.js" alt="Logo de React.js"/>
                <p>Sur le back-end, nous utilisons Node.js, un runtime JavaScript construit sur le moteur JavaScript V8 de Chrome. Node.js nous permet de construire des applications réseau rapides et évolutives. En utilisant Node.js, nous pouvons traiter plusieurs requêtes simultanément sans compromettre la vitesse ou l'efficacité. Cela garantit que nos opérations côté serveur sont robustes et peuvent gérer des volumes de trafic élevés sans effort.</p>
                <img src={process.env.PUBLIC_URL + '/images/node.png'} loading='lazy' title="Logo de Node.js" alt="Logo de Node.js"/>
                <p>Pour ajouter une autre couche de robustesse et d'efficacité, nous utilisons Symfony, un framework PHP connu pour sa fiabilité et sa flexibilité. Symfony nous aide à créer et à gérer facilement des logiques dorsales complexes. Son vaste ensemble de fonctionnalités et de composants réutilisables rationalise le processus de développement, ce qui nous permet de nous concentrer sur la prestation de services de haute qualité pour vous.</p>
                <img src={process.env.PUBLIC_URL + '/images/Symfony.png'} loading='lazy' title="Logo de Sympfony" alt="Logo de Symfony"/>
            </section>
            <section className="dataS">
                <h2>Data science</h2>
                <p>À l'ère du Big Data, les insights règnent en maître. En exploitant le pouvoir de la science des données, nous débloquons des potentiels cachés et dévoilons des insights exploitables. Grâce à l'analyse avancée et à la modélisation prédictive, nous permettons aux entreprises de prendre des décisions éclairées, d'optimiser les processus et de garder une longueur d'avance. Des recommandations personnalisées à la maintenance prédictive, nos solutions axées sur les données favorisent l'efficacité et la croissance.</p>
                <ul style={{listStyle:'none', margin:0, padding:0, display: "flex", alignItems:'center', justifyContent:'center'}}>
                    <li><img src={process.env.PUBLIC_URL + '/images/Python.png'} loading='lazy' title="Logo de Python" alt="Logo de Python"/></li>
                    <li><img src={process.env.PUBLIC_URL + '/images/Tableau.png'} loading='lazy' title="Logo de Tableau" alt="Logo de Tableau"/></li>
                    <li><img src={process.env.PUBLIC_URL + '/images/KNIME.png'} loading='lazy' title="Logo de KNIME" alt="Logo de KNIME"/></li>
                    <li><img src={process.env.PUBLIC_URL + '/images/Tensor.png'} loading='lazy' title="Logo de Tensorflow" alt="Logo de Tensorflow"/></li>
                    <li><img src={process.env.PUBLIC_URL + '/images/k3.png'} loading='lazy' title="Logo de Keras" alt="Logo de Keras"/></li>
                </ul>
                <p>Nous sommes ravis de vous présenter nos programmes de pointe en science des données et en intelligence artificielle, conçus pour vous permettre de profiter des dernières avancées en matière d'intelligence artificielle et d'apprentissage automatique. Notre plateforme s'appuie sur les puissantes capacités de Keras et TensorFlow pour fournir des solutions hautes performances adaptées à vos besoins.</p>
                <p>Notre équipe de data scientists expérimentés et de spécialistes de l'IA sont habiles à tirer parti de Keras et de TensorFlow pour s'attaquer à un large éventail d'applications, notamment:</p>
                <ul id="AI">
                    <li>Analyse prédictive: Utilisez des modèles d'apprentissage automatique pour prédire les tendances et les comportements futurs, permettant une prise de décision proactive.</li>
                    <li>Traitement du langage naturel: Développer des algorithmes sophistiqués pour comprendre et générer le langage humain, en améliorant les interfaces de communication et l'analyse des sentiments.</li>
                    <li>Vision par ordinateur: Mettre en œuvre une analyse avancée des images et des vidéos pour des applications telles que la détection d'objets, la reconnaissance faciale et l'imagerie médicale.</li>
                    <li>Systèmes de recommandation: Créez des moteurs de recommandation personnalisés qui améliorent l'expérience utilisateur et l'engagement.</li>
                    <li>Analyse des séries chronologiques: Analysez et prévoyez les données temporelles pour découvrir les tendances et les informations qui orientent les stratégies commerciales.</li>
                </ul>
            </section>
            <section className="game">
                <h2>Jeux vidéo</h2>
                <p>Le divertissement ne connaît pas de limites dans le domaine des jeux vidéo. Chez Matthieu Sibert International, nous exploitons le pouvoir du jeu pour créer des expériences immersives qui transcendent les frontières. Avec un mélange de créativité et de prouesses techniques, nous redéfinissons le divertissement interactif et repoussons les limites de l'imagination.</p>
                <img src={process.env.PUBLIC_URL + '/images/game.gif'} loading='lazy' title="Jeux vidéo de Matthieu Sibert International" alt="Un téléphone noir paysagé avec Pacman mangeant le logo de Matthieu Sibert International"/>
                <p>Nous sommes ravis de vous présenter notre collection diversifiée de jeux vidéo, développés avec expertise en utilisant Unity et Unreal Engine. Notre mission est d'offrir des expériences de jeu inoubliables qui captivent, défient et divertissent les joueurs du monde entier.</p>
                <div>
                    <img src={process.env.PUBLIC_URL + '/images/Unreal.png'} loading='lazy' title="Logo de Unreal engine" alt="Logo de Unreal engine"/>
                    <img src={process.env.PUBLIC_URL + '/images/Unity.png'} loading='lazy' title="Logo de Unity" alt="Logo d eunity"/>
                </div>
            </section>
            <section className="finance">
                <h2>Finance</h2>
                <p>Découvrez une gamme de produits financiers innovants conçus pour répondre à vos divers besoins. Des options de placement personnalisées aux solutions complètes de gestion des risques, nos offres visent à vous aider à atteindre vos objectifs financiers en toute confiance. Collaborez avec nous pour obtenir des conseils d'experts et des outils financiers flexibles.</p>
                <img src={process.env.PUBLIC_URL + '/images/finance.png'} loading='lazy' title="Produits financiers de Matthieu Sibert International" alt="Une impression artistique d’un ordinateur à l'intérieur d'un autre"/>
            </section>
        </main>
        </HelmetProvider>
     );
}
 
export default AboutFR;
