import {Fragment} from 'react'

import Nav from './components/Nav'
import Footer from './components/Footer';

function App() {
  // console.clear()
  console.log('Matthieu Sibert International')
  return (
    <Fragment>
      <Nav/>
      <Footer/>
    </Fragment>
  );
}

export default App;
