import axios from 'axios';
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Contact = ({page, title}) => {

    const [LastName, setLastName] = useState('')
    const [FirstName, setFirstName] = useState('')
    const [mail, setMail] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    
    const makeLastName = (e) => {
        setLastName(e.target.value)
    }

    const makeFirstName = (e) => {
        setFirstName(e.target.value)
    }

    const makeMail = (e) => {
        setMail(e.target.value)
    }

    const makeMsg = (e) => {
        setMessage(e.target.value)
    }

    const makeReset = () => {
        setLastName('')
        setFirstName('')
        setMail('')
        setMessage('')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(LastName.length === 0){
            alert('Please enter your last name')
        }else if(FirstName.length === 0){
            alert('Please enter your first name')
        }else if(mail.length === 0){
            alert('Please enter your mail address')
        }else if(message.length === 0){
            alert('Please enter your message')
        }else{
            setLoading(true)
            const formData = new FormData()
            formData.append('LastName', LastName)
            formData.append('FirstName', FirstName)
            formData.append('mail', mail)
            formData.append('message', message)
            try {
                const response = await axios.post('https://matthieu-sibert-international.me/ContactEn/index.php', formData);
                if (response.status === 200) {
                  alert('Message sent successfully!');
                  // Clear form fields
                  setLastName('')
                  setFirstName('')
                  setMail('')
                  setMessage('')
                } else {
                  alert('Failed to send message.');
                }
              } catch (error) {
                console.error('There was an error sending the message:', error);
                alert('There was an error sending your message. Please try again later.');
              } finally {
                setLoading(false)
              }
        }
    };
    
    useEffect(() => {
        page(3) 
        title('Matthieu Sibert International Contact Information')
    }, [page, title])

    return ( 
        <HelmetProvider>
        <main className="contact">
            <Helmet htmlAttributes={{lang:"en"}}>
                <title>Matthieu Sibert International - Contact</title>
                <meta name="description" content="Contact form of Matthieu Sibert International" />
                <link rel="canonical" href="https://www.matthieu-sibert-international.me/Contact"/>
            </Helmet>
            <section>
                <h2>Contact Form</h2>
                <form onSubmit={handleSubmit} autoComplete="true">
                    <div>
                        <label htmlFor="LastNameInput" >Last name :</label>
                        <input name="LastName" id="LastNameInput" type="text" placeholder="Please enter your last name"  value={LastName} onChange={makeLastName} />
                    </div>
                    <div>
                        <label htmlFor="FirstNameInput">First name :</label>
                        <input id="FirstNameInput" name="FirstName" type="text" placeholder="Please enter your first name"  value={FirstName} onChange={makeFirstName}/>
                    </div>
                    <div>
                        <label htmlFor="mailInput">Email :</label>
                        <input id="mailInput" name="mail" type="email" placeholder="Please enter your mail address"  value={mail} onChange={makeMail}/>
                    </div>
                    <div>
                        <label htmlFor="messageInput">Your message :</label>
                        <textarea id="messageInput" name="message" rows={25} value={message} onChange={makeMsg}></textarea>
                    </div>
                    <div style={{textAlign:'center'}}>
                        <button type="reset" style={{backgroundColor:'#fa4646'}} onClick={makeReset} disabled={loading}>Reset</button>
                        <button type="submit" style={{backgroundColor:'#30e395'}} disabled={loading}>Send</button>
                    </div>
                </form>
            </section>
        </main>
        </HelmetProvider>
    );
}
 
export default Contact;